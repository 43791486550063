<template lang="pug">
include ../../../configs/template
div
  div(v-if="!sailorDocument.is_continue && (!sailorDocument.is_payed || !sailorDocument.is_payed_blank)").w-100
    +select('body.is_payed_blank', 'paymentStatus', 'blankPayment', 'labelName')(item-value="status")
  div(v-if="!sailorDocument.is_payed || (!sailorDocument.is_payed_blank && !sailorDocument.is_continue)").w-100
    +select('body.is_payed', 'paymentStatus', 'payment', 'labelName')(item-value="status")

  div(v-else).w-100
    +select('body.status_document', 'statusChoose("StatementSailorPassport")', 'status', 'labelName')(item-value="id")

  div.w-100.mt-3
    Reject(v-if="STATUSES_STATEMENT_CONSTANTS.SAILOR.SAILOR_PASSPORT.REJECT === body.status_document")

  div.w-100.d-flex.justify-content-around.mt-3
    v-btn(
      v-if="STATUSES_STATEMENT_CONSTANTS.SAILOR.SAILOR_PASSPORT.REJECT !== body.status_document"
      @click="updateStatement"
      :loading="isLoading"
      color="success") {{ $t('save') }}
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
export default {
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  components: {
    Reject: () => import('./Reject.vue')
  },
  data () {
    return {
      STATUSES_STATEMENT_CONSTANTS,
      payment: null,
      blankPayment: null,
      body: {
        status_document: null || this.sailorDocument.status_document,
        is_payed: false || this.sailorDocument.is_payed,
        is_payed_blank: false || this.sailorDocument.is_payed_blank
      },
      isLoading: false
    }
  },
  computed: {
    ...mapGetters(['paymentStatusByStatus', 'statusChoose']),
    ...mapState({
      id: state => state.sailor.sailorId,
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      paymentStatus: state => state.directory.paymentStatus
    })
  },
  mounted () {
    Object.keys(this.body).forEach(key => (this.body[key] = this.sailorDocument[key]))
  },
  methods: {
    ...mapActions(['updateSailorPassportStatements']),

    async updateStatement () {
      this.isLoading = true

      if (!this.sailorDocument.is_continue && (!this.sailorDocument.is_payed || !this.sailorDocument.is_payed_blank)) delete this.body.status_document
      if ((this.sailorDocument.is_payed && this.sailorDocument.is_continue) || (!this.sailorDocument.is_continue && this.sailorDocument.is_payed && this.sailorDocument.is_payed_blank)) {
        delete this.body.is_payed
        delete this.body.is_payed_blank
      }
      await this.updateSailorPassportStatements({ ...this.$route.params, body: this.body })
      this.isLoading = false
    }
  }
}
</script>
